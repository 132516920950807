import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import  { useForm } from '../../hooks/useForm';
import Swal from 'sweetalert2';
import { crearEstablecimiento } from '../../action/establecimiento';
import { clearUploadFile, startUploadingFile } from '../../helpers/thunks';
import { establecimientosTabla } from '../../action/router';

export const FormEstablecimientoNuevo = () => {
    const dispatch = useDispatch();
    const { file } = useSelector(state => state.file);
    const [establecimiento, handleInputChange] = useForm({
        rut: '',
        nombre: '',
        rbd:'',
        razonSocial:'',
        emblema: '',
    });

    const { rut, nombre, rbd, razonSocial } = establecimiento;

    const handleEnviar = (e) => {
        e.preventDefault()
        if( rut === '' || nombre === '' || rbd === '' || razonSocial === '' ){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        if(file){
            establecimiento.emblema = file;
        }
        dispatch(crearEstablecimiento(establecimiento));
        dispatch(clearUploadFile(false))
        dispatch(establecimientosTabla())
    }

    const handleCancelar = (e) =>{
        e.preventDefault()
        dispatch(clearUploadFile(false))
        dispatch(establecimientosTabla())
    }

    const handleUploadFile = (e) =>{
        Swal.fire({
            icon: 'warning',
            title: 'Subiendo emblema espere...',
            showConfirmButton: false,
            timer: 4000
        });
        const file = e.target.files[0];
        if( file === 0 ) return;
        dispatch(startUploadingFile(file));
    };
     
    return (
    <form className='card'>
        <div className='card-body'>
            <h5>Formulario ingreso establecimiento</h5>
            <div className="row mb-3 mt-3">
                <label htmlFor="rut" className="col-2 col-form-label">Rut</label>
                <div className="col-4">
                    <input 
                        type="rut" 
                        className="form-control" 
                        id="rut"
                        name="rut"
                        value={ rut }
                        onChange={ handleInputChange }
                    />
                </div>
                <label htmlFor="nombre" className="col-2 col-form-label">Nombre</label>
                <div className="col-4">
                    <input 
                        type="nombre" 
                        className="form-control" 
                        id="nombre"
                        name="nombre"
                        value={ nombre }
                        onChange={ handleInputChange }
                    />
                </div>
                
            </div>
            <div className="row mb-3 mt-3">
                <label htmlFor="rbd" className="col-2 col-form-label">RBD</label>
                <div className="col-4">
                    <input 
                        type="rbd" 
                        className="form-control" 
                        id="rbd"
                        name="rbd"
                        value={ rbd }
                        onChange={ handleInputChange }
                    />
                </div>
                <label htmlFor="razonSocial" className="col-2 col-form-label">Razon Social</label>
                <div className="col-4">
                    <input 
                        type="razonSocial" 
                        className="form-control" 
                        id="razonSocial"
                        name="razonSocial"
                        value={ razonSocial }
                        onChange={ handleInputChange }
                    />
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <label htmlFor="emblema" className="col-2 col-form-label">Emblema</label>
                <div className="col-5">
                    <input 
                        id="inputGroupFile01"
                        type="file" 
                        className="form-control" 
                        name="emblema"
                        onChange={ handleUploadFile }
                    />
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <div className="col-4">
                    <button 
                        type="submit" 
                        className="btn btn-primary" 
                        onClick={ handleEnviar }
                    >Crear</button>
                </div>
                <div className="col-4">
                    <button 
                        type="submit" 
                        className="btn btn-danger" 
                        onClick={ handleCancelar }
                    >Cancelar</button>
                </div>
            </div>

        </div>

    </form>
  )
}