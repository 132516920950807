import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { obtenerCategoriasNoticiaEstablecimiento } from '../../action/categoriaNoticia';
import { activarEstablecimiento } from '../../action/establecimiento';
import { obtenerNoticiasEstablecimiento } from '../../action/noticia';
import { establecimientosTabla } from '../../action/router';
import { BotonCategoria } from './categoriaNoticia/BotonCategoria';
import { BotonCategoriaNueva } from './categoriaNoticia/BotonCategoriaNueva';
import { FormCategoriaActualizar } from './categoriaNoticia/FormCategoriaActualizar';
import { FormCategoriaNueva } from './categoriaNoticia/FormCategoriaNueva';
import { TablaCategoriaNoticia } from './categoriaNoticia/TablaCategoriaNoticia'
import { BotonNoticiaNueva } from './noticia/BotonNoticiaNueva';
import { FormNoticiaActualizar } from './noticia/FormNoticiaActualizar';
import { FormNoticiaNueva } from './noticia/FormNoticiaNueva';
import { TablaNoticias } from './noticia/TablaNoticias'

export const NoticiasPrincipal = ({establecimientoActivo}) => {
    const dispatch = useDispatch();
    const { categoriasNoticia, categoriaNoticiaActiva, noticias, noticiaActiva } = useSelector( state => state.establecimiento)
    const { 
        establecimientoTablaNoticias,
        establecimientoFormNoticiaNueva,
        establecimientoFormNoticiaActualizar,
        establecimientoTablaCategoriaNoticias,
        establecimientoFormCategoriaNueva,
        establecimientoFormCategoriActualizar,
    } = useSelector( state => state.router );

    useEffect(() => {
        if(establecimientoActivo){
            const filtro = {
                establecimiento: establecimientoActivo
            }
            const obtenerCategoriasNoticiaEstablecimientoPrincipal = () => dispatch(obtenerCategoriasNoticiaEstablecimiento(filtro));
            const obtenerNoticiasEstablecimientosPrincipal = () => dispatch(obtenerNoticiasEstablecimiento(filtro));
            obtenerCategoriasNoticiaEstablecimientoPrincipal()
            obtenerNoticiasEstablecimientosPrincipal()
        }
        // eslint-disable-next-line
    }, [establecimientoActivo]);

    const handleRegresar = () => {
        dispatch(activarEstablecimiento(""))
        dispatch(establecimientosTabla())
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-12'>
                        <h4>
                            { establecimientoTablaNoticias && 'Noticias Establecimiento' }
                            { establecimientoTablaCategoriaNoticias && 'Categoria Noticias Establecimiento' }
                        </h4>
                    </div>
                    <div className='col-12'>
                        <div className='row'>
                            { establecimientoTablaNoticias && 
                                <div className='col-3'>
                                    <div className='d-grid gap-2'>
                                        <BotonCategoria />
                                    </div>
                                </div>
                            }
                            { establecimientoTablaNoticias && 
                                <div className='col-3'>
                                    <div className='d-grid gap-2'>
                                        <BotonNoticiaNueva />
                                    </div>
                                </div>
                            }
                            { establecimientoTablaCategoriaNoticias && 
                                <div className='col-3'>
                                    <div className='d-grid gap-2'>
                                        <BotonCategoriaNueva />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='col-12'>
                        { establecimientoTablaNoticias && <TablaNoticias  categoriasNoticia={ categoriasNoticia } noticias={ noticias } /> }
                        { establecimientoFormNoticiaNueva && <FormNoticiaNueva  establecimiento={establecimientoActivo}  categoriasNoticia={ categoriasNoticia } /> }
                        { establecimientoFormNoticiaActualizar && <FormNoticiaActualizar noticiaActiva={ noticiaActiva }  categoriasNoticia={ categoriasNoticia } /> }
                        { establecimientoTablaCategoriaNoticias && <TablaCategoriaNoticia categoriasNoticia={ categoriasNoticia } /> }
                        { establecimientoFormCategoriaNueva && <FormCategoriaNueva  establecimiento={establecimientoActivo} /> }
                        { establecimientoFormCategoriActualizar && <FormCategoriaActualizar categoriaNoticiaActiva={categoriaNoticiaActiva} /> }
                    </div>
                </div>
                
                <div className='col-3'>
                    <button className='btn btn-danger' onClick={ handleRegresar } >Regresar</button>
                </div>
                
                
            </div>
        </div>
  )
}
