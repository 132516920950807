import React from 'react'
import { RowSinData } from '../../RowSinData'
import { RowNoticia } from './RowNoticia'

export const TablaNoticias = ({noticias, categoriasNoticia}) => {
    return (
        <table className='table table-bordered table-hover'>
            <thead>
                <tr>
                    <th scope="col">N°</th>
                    <th scope="col">Titulo</th>
                    <th scope="col">Fecha publicacion</th>
                    <th scope="col">Categoria</th>
                    <th scope="col">Creador</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Modificar</th>
                    <th scope="col">Eliminar</th>
                </tr>
            </thead>
            <tbody>
                {
                    categoriasNoticia.length > 0 && noticias.length > 0 
                        ? noticias.map((e, index) => (
                            <RowNoticia 
                                key={e._id}
                                noticia={e}
                                index={index + 1} 
                                categoriasNoticia ={categoriasNoticia }                      
                            />
                        ))
                        : <RowSinData />
                }

            </tbody>
        </table>
    )
}
