import React from 'react'
import { useDispatch } from 'react-redux';
import { activarEstablecimiento, actualizarEstablecimiento } from '../../action/establecimiento';
import { establecimientoContenido, establecimientoFormActualizar, establecimientoNoticiasPrincipal, establecimientoTablaNoticias } from '../../action/router';

export const RowEstablecimiento = ({establecimiento, index}) => {
    const dispatch = useDispatch()
    const { _id, nombre, rbd, rut, razonSocial, estado } = establecimiento;
    
 

    const handleActivar = () => {
        establecimiento.estado = 'true'
        dispatch(actualizarEstablecimiento(establecimiento))
    }

    const handleDesactivar = () => {
        establecimiento.estado = 'false'
        dispatch(actualizarEstablecimiento(establecimiento))
    }

    const handleContenido = () => {
        dispatch(activarEstablecimiento(_id))
        dispatch(establecimientoContenido())
    }

    const handleActualizar = () => {
        dispatch(activarEstablecimiento(_id))
        dispatch(establecimientoFormActualizar())
    }

    const handleNoticia = () => {
        dispatch(activarEstablecimiento(_id))
        dispatch(establecimientoNoticiasPrincipal())
        dispatch(establecimientoTablaNoticias())
    }


    return (
        <tr>
            <th>{index}</th>
            <td>{rut}</td>
            <td>{nombre}</td>
            <td>{rbd}</td>
            <td>{razonSocial}</td>
            <td>
                {estado 
                    ? 
                        <div className='d-grid gap-2'>
                            <button className='btn btn-success btn-sm' onClick={ handleDesactivar } >activo</button>
                        </div>
                    : 
                        <div className='d-grid gap-2'>
                            <button className='btn btn-danger btn-sm' onClick={ handleActivar } >inactivo</button>
                        </div>
                }
            </td>
            <td>
                <div className='d-grid gap-2'>
                    <button className='btn btn-primary btn-sm' onClick={ handleContenido } >Contenido</button>
                </div>
            </td>
            <td>
                <div className='d-grid gap-2'>
                    <button className='btn btn-primary btn-sm' onClick={ handleNoticia } >Noticias</button>
                </div>
            </td>
            <td>
                <div className='d-grid gap-2'>
                    <button className='btn btn-warning btn-sm' onClick={ handleActualizar } >Actualizar</button>
                </div>
            </td>
        </tr>
    )
}
