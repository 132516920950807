import React from 'react'
import { useDispatch } from 'react-redux'
import { establecimientoTablaCategoriaNoticias } from '../../../action/router';

export const BotonCategoria = () => {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(establecimientoTablaCategoriaNoticias());
    }

    return (
        <button className='btn btn-primary' onClick={ handleClick } >Ver Categorias</button>
    )
}
  