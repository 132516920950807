import React, { useEffect } from 'react'
import { navigate } from "gatsby";
import { useDispatch, useSelector } from 'react-redux'
import { obtenerEstablecimientos } from '../../action/establecimiento'
import { BotonEstablecimientoNuevo } from './BotonEstablecimientoNuevo'
import { ContenidoEstablecimiento } from './ContenidoEstablecimiento'
import { FormEstablecimientoActualizar } from './FormEstablecimientoActualizar'
import { FormEstablecimientoNuevo } from './FormEstablecimientoNuevo'
import { FormInformacionActualizar } from './informacion/FormInformacionActualizar'
import { NoticiasPrincipal } from './NoticiasPrincipal'
import { TablaEstablecimientos } from './TablaEstablecimientos'

export const AdministradorEstablecimientoScreen = () => {
  const dispatch = useDispatch()
  const { uid } = useSelector( state => state.auth );
  const { establecimientos, establecimientoActivo } = useSelector( state => state.establecimiento );
  const { 
    establecimientoFormNuevo,
    establecimientoFormActualizar,
    establecimientosTabla,
    establecimientoContenido,
    establecimientoNoticiasPrincipal,
    establecimientoFormInformacionActualizar,
  } = useSelector( state => state.router );

  useEffect(() => {
      const obtenerEstablecimientosPrincipal = () => dispatch(obtenerEstablecimientos());
      obtenerEstablecimientosPrincipal()
      // eslint-disable-next-line
  }, []);

  if(!uid){
    setTimeout(() => {
      navigate("/login/")
    }, 1500);
  }
  return (

    <div className='row'>
        <div className='col-12'>
            <div className='h3 text-light'>Administrador establecimiento</div>
        </div>
        <div className='col-12'>
          { establecimientosTabla && 
            <BotonEstablecimientoNuevo /> 
          }
        </div>
        <div className='col-12'>
          { establecimientosTabla && 
            <TablaEstablecimientos establecimientos = { establecimientos } /> 
          }
          { establecimientoFormNuevo && <FormEstablecimientoNuevo /> }
          { establecimientoFormActualizar && 
            <FormEstablecimientoActualizar 
              establecimientoActivo = { establecimientoActivo } 
            /> 
          }
          { establecimientoContenido && 
            <ContenidoEstablecimiento 
              establecimientoActivo = { establecimientoActivo } 
            /> 
          }
          { establecimientoNoticiasPrincipal && 
            <NoticiasPrincipal 
              establecimientoActivo = { establecimientoActivo } 
            /> 
          }
          { establecimientoFormInformacionActualizar && 
            <FormInformacionActualizar /> 
          }
        </div>
    </div>
  )
}
