import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import { activarCategoriaNoticia, actualizarCategoriaNoticia } from '../../../action/categoriaNoticia';
import { establecimientoTablaCategoriaNoticias } from '../../../action/router';
import { useForm } from '../../../hooks/useForm';

export const FormCategoriaActualizar = ({categoriaNoticiaActiva}) => {
  const dispatch = useDispatch()
  const { categoriasNoticia } = useSelector(state => state.establecimiento);
  const categoriaInicial = categoriasNoticia.find(e => e._id === categoriaNoticiaActiva);
  const [ categoriaNoticia, handleInputChange ] = useForm(categoriaInicial);
  const { titulo, comentario, fechaCreacion } = categoriaNoticia;

  const handleActualizar = (e) =>{
    e.preventDefault()
    if( titulo === '' || comentario === '' || fechaCreacion === '' ){
        return Swal.fire({
            icon: 'warning',
            title: 'Faltan campos por rellenar',
            showConfirmButton: false,
            timer: 1000
        });
    }
    
    dispatch(actualizarCategoriaNoticia(categoriaNoticia));
    dispatch(activarCategoriaNoticia(''))
    dispatch(establecimientoTablaCategoriaNoticias())
    };

    const handleCancelar = (e) =>{
        e.preventDefault()
        dispatch(activarCategoriaNoticia(''))
        dispatch(establecimientoTablaCategoriaNoticias())
    }

  return (
    <form>
    <div>
        <h5>Formulario actualizar categoria noticia</h5>
        <div className="row mb-3 mt-3">
            <label htmlFor="titulo" className="col-2 col-form-label">Titulo</label>
            <div className="col-4">
                <input 
                    type="text" 
                    className="form-control" 
                    id="titulo"
                    name="titulo"
                    value={ titulo }
                    onChange={ handleInputChange }
                />
            </div>
        </div>
        <div className="row mb-3 mt-3">
            <label htmlFor="comentario" className="col-2 col-form-label">Comentario</label>
            <div className="col-4">
                <input 
                    type="textbox" 
                    className="form-control" 
                    id="comentario"
                    name="comentario"
                    value={ comentario }
                    onChange={ handleInputChange }
                />
            </div>
            
        </div>
        <div className="row mb-3 mt-3">
            <label htmlFor="fechaCreacion" className="col-2 col-form-label">Fecha Creacion</label>
            <div className="col-4">
                <input 
                    type="date" 
                    className="form-control" 
                    id="fechaCreacion"
                    name="fechaCreacion"
                    value={ fechaCreacion }
                    onChange={ handleInputChange }
                />
            </div>
        </div>
        <div className="row mb-3 mt-3">
            <div className="col-4">
                <button 
                    type="submit" 
                    className="btn btn-primary" 
                    onClick={ handleActualizar }
                >Actualizar</button>
            </div>
            <div className="col-4">
                <button 
                    type="submit" 
                    className="btn btn-danger" 
                    onClick={ handleCancelar }
                >Cancelar</button>
            </div>
        </div>

    </div>

  </form>
  )
}
