import React from 'react'
import { useDispatch } from 'react-redux'
import { establecimientoTablaNoticias } from '../../../action/router'
import { RowCategoriaNoticia } from './RowCategoriaNoticia'

export const TablaCategoriaNoticia = ({ categoriasNoticia }) => {
    const dispatch = useDispatch();
    const handleRegresar = () => {
        dispatch(establecimientoTablaNoticias())
    }

    return (
        <table className='table table-bordered table-hover'>
            <thead>
                <tr>
                    <th scope="col">N°</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Fecha publicacion</th>
                    <th scope="col">Creador</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Modificar</th>
                    <th scope="col">Eliminar</th>
                </tr>
            </thead>
            <tbody>
                {categoriasNoticia.length > 0 && categoriasNoticia.map( (e, index) =>(
                    <RowCategoriaNoticia
                        key={e._id} 
                        categoria={e}
                        index={index + 1}
                    />
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div className='d-grid gap-2'>
                            <button className='btn btn-danger btn-sm' onClick={ handleRegresar } >Regresar</button>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}
