import React from 'react'
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { crearCategoriaNoticia } from '../../../action/categoriaNoticia';
import { establecimientoTablaCategoriaNoticias } from '../../../action/router';
import { useForm } from '../../../hooks/useForm';

export const FormCategoriaNueva = ({establecimiento}) => {
    const dispatch = useDispatch();
    const [categoriaNoticia, handleInputChange] = useForm({
        titulo: '',
        comentario: '',
        fechaCreacion:'',
        establecimiento: establecimiento
    });

    const { titulo, comentario, fechaCreacion } = categoriaNoticia;

    const handleEnviar = (e) => {
        e.preventDefault()
        if( titulo === '' || comentario === '' || fechaCreacion === '' ){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        dispatch(crearCategoriaNoticia(categoriaNoticia));
        dispatch(establecimientoTablaCategoriaNoticias())
    }

    const handleCancelar = (e) =>{
        e.preventDefault()
        dispatch(establecimientoTablaCategoriaNoticias())
    }

     
    return (
    <form>
        <div>
            <h5>Formulario ingreso categoria noticia</h5>
            <div className="row mb-3 mt-3">
                <label htmlFor="titulo" className="col-2 col-form-label">Titulo</label>
                <div className="col-4">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="titulo"
                        name="titulo"
                        value={ titulo }
                        onChange={ handleInputChange }
                    />
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <label htmlFor="comentario" className="col-2 col-form-label">Comentario</label>
                <div className="col-4">
                    <input 
                        type="textbox" 
                        className="form-control" 
                        id="comentario"
                        name="comentario"
                        value={ comentario }
                        onChange={ handleInputChange }
                    />
                </div>
                
            </div>
            <div className="row mb-3 mt-3">
                <label htmlFor="fechaCreacion" className="col-2 col-form-label">Fecha Creacion</label>
                <div className="col-4">
                    <input 
                        type="date" 
                        className="form-control" 
                        id="fechaCreacion"
                        name="fechaCreacion"
                        value={ fechaCreacion }
                        onChange={ handleInputChange }
                    />
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <div className="col-4">
                    <button 
                        type="submit" 
                        className="btn btn-primary" 
                        onClick={ handleEnviar }
                    >Crear</button>
                </div>
                <div className="col-4">
                    <button 
                        type="submit" 
                        className="btn btn-danger" 
                        onClick={ handleCancelar }
                    >Cancelar</button>
                </div>
            </div>

        </div>

    </form>
  )
}