import React from 'react'
import { useDispatch } from 'react-redux'
import { establecimientoFormNuevo } from '../../action/router'

export const BotonEstablecimientoNuevo = () => {
    const dispatch =useDispatch()
    const handleFormNuevo = () => {
        dispatch(establecimientoFormNuevo())
    }
    return (
        <button 
            type="submit" 
            className="btn btn-primary" 
            onClick={ handleFormNuevo }
        >Nuevo establecimiento</button>
    )
}
  