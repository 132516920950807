import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { activarEstablecimientoInformacion, actualizarInformacion } from '../../../action/informacionEstablecimiento';
import { establecimientoContenido } from '../../../action/router';
import { useForm } from '../../../hooks/useForm';

export const FormInformacionActualizar = () => {
  const dispatch = useDispatch();
  const {informacion} = useSelector(state => state.establecimiento)
  const [informacionNueva, handleInputChange] = useForm(informacion);
  const { telefono, telefono2, pais, region, ciudad, direccion } = informacionNueva;
  const handleActualizar = (e) => {
    e.preventDefault()
    // if( telefono === '' || telefono2 === '' || pais === '' || region === '' || ciudad === '' || direccion === ''){
    //     return Swal.fire({
    //         icon: 'warning',
    //         title: 'Faltan campos por rellenar',
    //         showConfirmButton: false,
    //         timer: 1000
    //     });
    // }
    dispatch(actualizarInformacion(informacionNueva));
    dispatch(activarEstablecimientoInformacion(""))
    dispatch(establecimientoContenido())
  }

  const handleCancelar = () => {
    dispatch(activarEstablecimientoInformacion(""))
    dispatch(establecimientoContenido())
  }


  return (
    <div className='card'>
      <div className='card-body'>

        <form>
          <div>
              <h5>Formulario actualizar informacion establecimiento</h5>
              <div className="row mb-3 mt-3">
                  <label htmlFor="telefono" className="col-2 col-form-label">Telefono</label>
                  <div className="col-4">
                      <input 
                          type="text" 
                          className="form-control" 
                          id="telefono"
                          name="telefono"
                          value={ telefono }
                          onChange={ handleInputChange }
                      />
                  </div>
                  <label htmlFor="telefono2" className="col-2 col-form-label">Telefono 2</label>
                  <div className="col-4">
                      <input 
                          type="text" 
                          className="form-control" 
                          id="telefono2"
                          name="telefono2"
                          value={ telefono2 }
                          onChange={ handleInputChange }
                      />
                  </div>
              </div>
              <div className="row mb-3 mt-3">
                  <label htmlFor="pais" className="col-2 col-form-label">Pais</label>
                  <div className="col-3">
                      <input 
                          type="text" 
                          className="form-control" 
                          id="pais"
                          name="pais"
                          value={ pais }
                          onChange={ handleInputChange }
                      />
                  </div>
                  <label htmlFor="region" className="col-2 col-form-label">Region</label>
                  <div className="col-3">
                      <input 
                          type="text" 
                          className="form-control" 
                          id="region"
                          name="region"
                          value={ region }
                          onChange={ handleInputChange }
                      />
                  </div>
                  <label htmlFor="ciudad" className="col-2 col-form-label">Ciudad</label>
                  <div className="col-3">
                      <input 
                          type="text" 
                          className="form-control" 
                          id="ciudad"
                          name="ciudad"
                          value={ ciudad }
                          onChange={ handleInputChange }
                      />
                  </div>
                  
              </div>
              <div className="row mb-3 mt-3">
                  <label htmlFor="direccion" className="col-2 col-form-label">Direccion</label>
                  <div className="col-4">
                      <input 
                          type="text" 
                          className="form-control" 
                          id="direccion"
                          name="direccion"
                          value={ direccion }
                          onChange={ handleInputChange }
                      />
                  </div>
              </div>
              <div className="row mb-3 mt-3">
                  <div className="col-4">
                      <button 
                          type="submit" 
                          className="btn btn-primary" 
                          onClick={ handleActualizar }
                      >Actualizar</button>
                  </div>
                  <div className="col-4">
                      <button 
                          type="submit" 
                          className="btn btn-danger" 
                          onClick={ handleCancelar }
                      >Cancelar</button>
                  </div>
              </div>

          </div>
        </form>
      </div>
    </div>
  )
}
