import React from 'react'
import { useDispatch } from 'react-redux';
import { establecimientoFormCategoriaNueva } from '../../../action/router';

export const BotonCategoriaNueva = () => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(establecimientoFormCategoriaNueva());
    }

    return (
        <button className='btn btn-primary' onClick={ handleClick } >Crear Categoria</button>
    )
}