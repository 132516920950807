import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { activarEstablecimiento } from '../../action/establecimiento';
import { activarEstablecimientoInformacion, limpiarEstablecimientoInformacion, obtenerInformacionEstablecimiento } from '../../action/informacionEstablecimiento';
import { establecimientoFormInformacionActualizar, establecimientosTabla } from '../../action/router';
import { FormInformacionNueva } from './informacion/FormInformacionNueva';

export const ContenidoEstablecimiento = ({establecimientoActivo}) => {
    const dispatch = useDispatch()
    const { establecimientos } = useSelector(state => state.establecimiento);
    let { informacion } = useSelector(state => state.establecimiento);
    const establecimientoInicial = establecimientos.find(e => e._id === establecimientoActivo);

    useEffect(() => {
        const filtro = {
          establecimiento: establecimientoActivo
        }
        const obtenerEstablecimientoInformacionPrincipal = () => dispatch(obtenerInformacionEstablecimiento(filtro));
        obtenerEstablecimientoInformacionPrincipal()
        // eslint-disable-next-line
    }, [establecimientoActivo]);
    
    if(!informacion){
        informacion = {}
    }


    const handleRegresar = (e) =>{
        e.preventDefault()
        dispatch(activarEstablecimiento(''))
        dispatch(limpiarEstablecimientoInformacion())
        dispatch(establecimientosTabla())
    }

    const handleActualizar = () => {
        dispatch(activarEstablecimientoInformacion(informacion._id))
        dispatch(establecimientoFormInformacionActualizar())
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <h4>Contenido Establecimientos</h4>
                <h6>Establecimiento</h6>
                <div className='row'>
                    <div className='col-4'>
                        <img src={establecimientoInicial.emblema} className="img-thumbnail" alt="Emblema establecimiento" />
                    </div>
                </div>
                <table>
                    <tbody>
                        <tr>
                            <th>Rut:</th>
                            <td> {establecimientoInicial.rut}</td>
                            <th>RBD:</th>
                            <td> {establecimientoInicial.rbd}</td>
                        </tr>
                        <tr>
                            <th>Nombre:</th>
                            <td> {establecimientoInicial.nombre}</td>
                        </tr>
                        <tr>
                            <th>Razon Social:</th>
                            <td> {establecimientoInicial.razonSocial}</td>
                        </tr>
                    </tbody>
                </table>
                <h6>Informacion</h6>
                { Object.entries(informacion).length> 0 
                    ?
                    <table>
                        <tbody>
                            <tr>
                                <th>Telefono:</th>
                                <td> {informacion.telefono}</td>
                                <th>Telefono2:</th>
                                <td> {informacion.telefono2}</td>
                            </tr>
                            <tr>
                                <th>Pais:</th>
                                <td> {informacion.pais}</td>
                                <th>Region:</th>
                                <td> {informacion.region}</td>
                                <th>Ciudad:</th>
                                <td> {informacion.ciudad}</td>
                            </tr>
                            <tr>
                                <th>Direccion:</th>
                                <td> {informacion.direccion}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <button className='btn btn-secondary' onClick={ handleActualizar }>Actualizar Informacion</button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    :
                    <FormInformacionNueva establecimientoActivo={establecimientoActivo} />
                }
            </div>
            <button 
                type="submit" 
                className="btn btn-danger" 
                onClick={ handleRegresar }
            >Regresar</button>
        </div>           
    )
}
