import React from 'react'
import { useDispatch } from 'react-redux'
import { establecimientoFormNoticiaNueva } from '../../../action/router';

export const BotonNoticiaNueva = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(establecimientoFormNoticiaNueva())
  }
  return (
    <button className='btn btn-primary' onClick={handleClick}>Nueva Noticia</button>
  )
}
