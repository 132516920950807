import { fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


// informacion

export const crearInformacion = ( informacion ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('establecimiento/crearInformacion', informacion, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearEstablecimientoInformacionOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearEstablecimientoInformacionError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearEstablecimientoInformacionError());
        }
    }
}
const crearEstablecimientoInformacionOk = (e) => ({
    type: types.crearEstablecimientoInformacionOk,
    payload: e
});

const crearEstablecimientoInformacionError = () => ({ type: types.crearEstablecimientoInformacionError });



export const obtenerInformacionEstablecimiento = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('establecimiento/obtenerInformacionEstablecimiento', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerEstablecimientoInformacionOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerEstablecimientoInformacionError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerEstablecimientoInformacionError());
        }
    }
}

const obtenerEstablecimientoInformacionOk = (e) => ({ 
    type: types.obtenerEstablecimientoInformacionOk ,
    payload: e
});

const obtenerEstablecimientoInformacionError = () => ({ type: types.obtenerEstablecimientoInformacionError });



export const actualizarInformacion = (informacion) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`establecimiento/actualizarInformacion/${informacion._id}`, informacion, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarEstablecimientoInformacionOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarEstablecimientoInformacionError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarEstablecimientoInformacionError())
        }
    }
}

const actualizarEstablecimientoInformacionOk = (e) => ({ 
    type: types.actualizarEstablecimientoInformacionOk,
    payload: e
});

const actualizarEstablecimientoInformacionError = () => ({ type : types.actualizarEstablecimientoInformacionError });

export const activarEstablecimientoInformacion = (e) => ({ 
    type: types.activarEstablecimientoInformacion,
    payload: e
});

export const limpiarEstablecimientoInformacion = (e) => ({ 
    type: types.limpiarEstablecimientoInformacion,
    payload: e
});