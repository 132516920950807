import { fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';

export const crearCategoriaNoticia = ( categoriaNoticia ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('establecimiento/crearCategoriaNoticia', categoriaNoticia, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearCategoriaNoticiaOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearCategoriaNoticiaError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearCategoriaNoticiaError());
        }
    }
}

const crearCategoriaNoticiaOk = (e) => ({
    type: types.crearCategoriaNoticiaOk,
    payload: e
});

const crearCategoriaNoticiaError = () => ({ type: types.crearCategoriaNoticiaError });


export const obtenerCategoriasNoticia = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('establecimiento/obtenerCategoriasNoticia');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerCategoriasNoticiaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerCategoriasNoticiaError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCategoriasNoticiaError());
        }
    }
}

const obtenerCategoriasNoticiaOk = (e) => ({ 
    type: types.obtenerCategoriasNoticiaOk ,
    payload: e
});

const obtenerCategoriasNoticiaError = () => ({ type: types.obtenerCategoriasNoticiaError });


export const obtenerCategoriasNoticiaEstablecimiento = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('establecimiento/obtenerCategoriasNoticiaEstablecimiento', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerCategoriasNoticiaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerCategoriasNoticiaError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCategoriasNoticiaError());
        }
    }
}



export const actualizarCategoriaNoticia = (categoriaNoticia) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`establecimiento/actualizarCategoriaNoticia/${categoriaNoticia._id}`, categoriaNoticia, 'PUT');
            const body = await resp.json();
            console.log(body)
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarCategoriaNoticiaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarCategoriaNoticiaError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarCategoriaNoticiaError())
        }
    }
}

const actualizarCategoriaNoticiaOk = (e) => ({ 
    type: types.actualizarCategoriaNoticiaOk,
    payload: e
});

const actualizarCategoriaNoticiaError = () => ({ type : types.actualizarCategoriaNoticiaError });

export const activarCategoriaNoticia = (e) => ({ 
    type: types.activarCategoriaNoticia,
    payload: e
});
