import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import { activarNoticia, actualizarNoticia } from '../../../action/noticia';
import { establecimientoTablaNoticias } from '../../../action/router';
import { useForm } from '../../../hooks/useForm';

export const FormNoticiaActualizar = ({noticiaActiva, categoriasNoticia}) => {
  const dispatch = useDispatch()
  const { noticias } = useSelector(state => state.establecimiento);
  const noticiaInicial = noticias.find(e => e._id === noticiaActiva);
  const [ noticia, handleInputChange ] = useForm(noticiaInicial);
  const { titulo, comentario, categoriaNoticia, fechaCreacion } = noticia;

  const handleActualizar = (e) =>{
    e.preventDefault()
    if( titulo === '' || comentario === '' || fechaCreacion === '' || categoriaNoticia === '' ){
        return Swal.fire({
            icon: 'warning',
            title: 'Faltan campos por rellenar',
            showConfirmButton: false,
            timer: 1000
        });
    }
    
    dispatch(actualizarNoticia(noticia));
    dispatch(activarNoticia(''))
    dispatch(establecimientoTablaNoticias())
    };

    const handleCancelar = (e) =>{
        e.preventDefault()
        dispatch(activarNoticia(''))
        dispatch(establecimientoTablaNoticias())
    }

  return (
    <form>
      <h5>Formulario actualizar noticia</h5>
      <div className="row mb-3 mt-3">
        <label htmlFor="titulo" className="col-2 col-form-label">Titulo</label>
        <div className="col-4">
            <input 
                type="text" 
                className="form-control" 
                id="titulo"
                name="titulo"
                value={ titulo }
                onChange={ handleInputChange }
            />
        </div>
    </div>
    <div className="row mb-3 mt-3">
        <label htmlFor="comentario" className="col-2 col-form-label">Comentario</label>
        <div className="col-4">
            <input 
                type="text" 
                className="form-control" 
                id="comentario"
                name="comentario"
                value={ comentario }
                onChange={ handleInputChange }
            />
        </div>
        
    </div>
    <div className="row mb-3 mt-3">
        <label htmlFor="fechaCreacion" className="col-2 col-form-label">Fecha Creacion</label>
        <div className="col-4">
            <input 
                type="date" 
                className="form-control" 
                id="fechaCreacion"
                name="fechaCreacion"
                value={ fechaCreacion }
                onChange={ handleInputChange }
            />
        </div>
    </div>
    <div className="row mb-3 mt-3">
        <label htmlFor="categoriaNoticia" className="col-2 col-form-label">Categoria Noticia</label>
        <select 
          className="form-select" 
          aria-label="Default select example"
          onChange={handleInputChange}
          value={categoriaNoticia}  
          name="categoriaNoticia" 
        >
          <option value="" defaultValue>Seleccione...</option>
          { categoriasNoticia.length > 0 && categoriasNoticia.map((e) =>(
            <option 
              value={e._id}
              key={e._id}
            >{e.titulo}</option>
          ))}
        </select>
    </div>
    <div className="row mb-3 mt-3">
        <div className="col-4">
            <button 
                type="submit" 
                className="btn btn-primary" 
                onClick={ handleActualizar }
            >Actualizar</button>
        </div>
        <div className="col-4">
            <button 
                type="submit" 
                className="btn btn-danger" 
                onClick={ handleCancelar }
            >Cancelar</button>
        </div>
    </div>
    </form>
  )
}
