import { fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearNoticia = ( noticia ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('establecimiento/crearNoticia', noticia, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearNoticiaOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearNoticiaError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearNoticiaError());
        }
    }
}

const crearNoticiaOk = (e) => ({
    type: types.crearNoticiaOk,
    payload: e
});

const crearNoticiaError = () => ({ type: types.crearNoticiaError });


export const obtenerNoticias = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('establecimiento/obtenerNoticias');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerNoticiasOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerNoticiasError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerNoticiasError());
        }
    }
}

export const obtenerNoticiasEstablecimiento = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('establecimiento/obtenerNoticiasEstablecimiento', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerNoticiasOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerNoticiasError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerNoticiasError());
        }
    }
}

const obtenerNoticiasOk = (e) => ({ 
    type: types.obtenerNoticiasOk ,
    payload: e
});

const obtenerNoticiasError = () => ({ type: types.obtenerNoticiasError });



export const actualizarNoticia = (noticia) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`establecimiento/actualizarNoticia/${noticia._id}`, noticia, 'PUT');
            const body = await resp.json();
            console.log(body)
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarNoticiaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarNoticiaError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarNoticiaError())
        }
    }
}

const actualizarNoticiaOk = (e) => ({ 
    type: types.actualizarNoticiaOk,
    payload: e
});

const actualizarNoticiaError = () => ({ type : types.actualizarNoticiaError });

export const activarNoticia = (e) => ({ 
    type: types.activarNoticia,
    payload: e
});
