import React from 'react'
import { useDispatch } from 'react-redux';
import { activarCategoriaNoticia, actualizarCategoriaNoticia } from '../../../action/categoriaNoticia';
import { establecimientoFormCategoriActualizar } from '../../../action/router';

export const RowCategoriaNoticia = ({categoria, index}) => {
    const dispatch = useDispatch();
    const { titulo, fechaCreacion, estado } = categoria;

    const handleActivar = () => {
        categoria.estado = 'true'
        dispatch(actualizarCategoriaNoticia(categoria))
    }

    const handleDesactivar = () => {
        categoria.estado = 'false'
        dispatch(actualizarCategoriaNoticia(categoria))
    }

    const handleActualizar = () => {
        dispatch(activarCategoriaNoticia(categoria._id))
        dispatch(establecimientoFormCategoriActualizar())
    }

    return (
        <tr>
            <th>{index}</th>
            <td>{titulo}</td>
            <td>{fechaCreacion}</td>
            <td>Creador</td>
            <td>
                {estado 
                    ? <div className='d-grid gap-2'><button className='btn btn-success btn-sm' onClick={ handleDesactivar } >Activo</button></div>
                    : <div className='d-grid gap-2'><button className='btn btn-danger btn-sm' onClick={ handleActivar } >Inactivo</button></div>
                }
            </td>
            <td><div className='d-grid gap-2'><button className='btn btn-warning btn-sm' onClick={ handleActualizar } >Modificar</button></div></td>
            <td><div className='d-grid gap-2'><button className='btn btn-danger btn-sm'>Eliminar</button></div></td>
        </tr>
    )
}
