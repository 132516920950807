import React from 'react'
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { crearInformacion } from '../../../action/informacionEstablecimiento';
import { useForm } from '../../../hooks/useForm';

export const FormInformacionNueva = ({establecimientoActivo}) => {
    const dispatch = useDispatch();
    const [informacion, handleInputChange] = useForm({
        telefono: '',
        telefono2: '',
        pais:'',
        region: '',
        ciudad: '',
        direccion: '',
        establecimiento: establecimientoActivo,
    });

    const { telefono, telefono2, pais, region, ciudad, direccion } = informacion;

    const handleEnviar = (e) => {
        e.preventDefault()
        if( telefono === '' || telefono2 === '' || pais === '' || region === '' || ciudad === '' || direccion === ''){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        dispatch(crearInformacion(informacion));
    }

    return (
        <form>
            <div>
                <h5>Formulario ingreso informacion establecimiento</h5>
                <div className="row mb-3 mt-3">
                    <label htmlFor="telefono" className="col-2 col-form-label">Telefono</label>
                    <div className="col-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="telefono"
                            name="telefono"
                            value={ telefono }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <label htmlFor="telefono2" className="col-2 col-form-label">Telefono 2</label>
                    <div className="col-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="telefono2"
                            name="telefono2"
                            value={ telefono2 }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <div className="row mb-3 mt-3">
                    <label htmlFor="pais" className="col-2 col-form-label">Pais</label>
                    <div className="col-3">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="pais"
                            name="pais"
                            value={ pais }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <label htmlFor="region" className="col-2 col-form-label">Region</label>
                    <div className="col-3">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="region"
                            name="region"
                            value={ region }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <label htmlFor="ciudad" className="col-2 col-form-label">Ciudad</label>
                    <div className="col-3">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="ciudad"
                            name="ciudad"
                            value={ ciudad }
                            onChange={ handleInputChange }
                        />
                    </div>
                    
                </div>
                <div className="row mb-3 mt-3">
                    <label htmlFor="direccion" className="col-2 col-form-label">Direccion</label>
                    <div className="col-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="direccion"
                            name="direccion"
                            value={ direccion }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <div className="row mb-3 mt-3">
                    <div className="col-4">
                        <button 
                            type="submit" 
                            className="btn btn-primary" 
                            onClick={ handleEnviar }
                        >Crear</button>
                    </div>
                </div>

            </div>

        </form>
    )
}
