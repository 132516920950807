
import * as React from "react"
import { AdministradorEstablecimientoScreen } from "../components/administradorEstablecimiento/administradorEstablecimientoScreen"
import Layout from '../components/layout'


// markup
const administradorEstablecimiento = () => {
  return (
    <Layout>
      <div className="container-xl">
        <AdministradorEstablecimientoScreen />
      </div>
    </Layout>
  )
}

export default administradorEstablecimiento
