import React from 'react'
import { useDispatch } from 'react-redux';
import { activarNoticia, actualizarNoticia } from '../../../action/noticia';
import { establecimientoFormNoticiaActualizar } from '../../../action/router';
import { Loading } from '../../Loading';

export const RowNoticia = ({noticia, index, categoriasNoticia}) => {
    const dispatch = useDispatch();
    const { titulo, fechaCreacion, categoriaNoticia, estado } =noticia;
    
    const categoriaNoticiaPrincipal = categoriasNoticia.find( e => e._id === categoriaNoticia)
    
    const handleActivar = () => {
        noticia.estado = 'true'
        dispatch(actualizarNoticia(noticia))
    }

    const handleDesactivar = () => {
        noticia.estado = 'false'
        dispatch(actualizarNoticia(noticia))
    }

    const handleActualizar = () => {
        dispatch(activarNoticia(noticia._id))
        dispatch(establecimientoFormNoticiaActualizar())
    }

    return (
        <tr>
            <th>{index}</th>
            <td>{titulo}</td>
            <td>{fechaCreacion}</td>
            <td>{categoriaNoticiaPrincipal ? categoriaNoticiaPrincipal.titulo : ''}</td>
            <td>Creador</td>
            <td>
                {estado 
                    ? <div className='d-grid gap-2'><button className='btn btn-success btn-sm' onClick={ handleDesactivar } >Activo</button></div>
                    : <div className='d-grid gap-2'><button className='btn btn-danger btn-sm' onClick={ handleActivar } >Inactivo</button></div>
                }
            </td>
            <td><div className='d-grid gap-2'><button className='btn btn-warning btn-sm' onClick={ handleActualizar } >Modificar</button></div></td>
            <td><div className='d-grid gap-2'><button className='btn btn-danger btn-sm'>Eliminar</button></div></td>
        </tr>
    )
}
